import Vue from 'vue'
import VueRouter from 'vue-router'
import localStore from 'store'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, _, next) => {
  const user = localStore.get('yololive-user')

  store.commit('currentRouteUpdate', to)
  /**
   * 登录界面
   */
  if (to.path.startsWith('/user')) {
    if (user) {
      next('/')
    } else {
      next()
    }
    return
  }
  /**
   * 应用界面
   * 判断用户信息, 只有获取用户信息之后才运行之后的程序
   */
  if (!user) {
    next('/user/login')
    return
  }
  next()
})

router.afterEach(function (to) {
  if (to.path.startsWith('/user')) {
    document.title = 'yololiv 内部管理后台'
  } else {
    document.title = to.meta.title
  }
})

export default router
