import { Message } from 'element-ui'
import moment from 'moment'
import AWS from 'aws-sdk'
import serve from '@/serve'
import { randomString } from './tools'

const plupload = window.plupload

class AwsUploader {
  constructor({
    browse_button,
    suffix = 'default',
    filters = {},
    random = true,
    BeforeUpload,
    UploadComplete
  } = {}) {
    this.browse_button = browse_button
    this.suffix = suffix
    this.uploader = null
    this.s3 = null
    this.awsTokens = null
    this.filters = filters
    this.processTxtEl = null
    this.random = random
    this.BeforeUpload = BeforeUpload
    this.UploadComplete = UploadComplete
    this.s3upload = null
    this.init()
  }

  init() {
    this.creaeteUploader()
  }

  creaeteUploader() {
    this.uploader = new plupload.Uploader({
      runtimes: 'html5,flash,silverlight,html4',
      browse_button: this.browse_button,
      flash_swf_url: '../plupload-2.1.2/js/Moxie.swf',
      silverlight_xap_url: '../plupload-2.1.2/js/Moxie.xap',
      url: '/',
      filters: this.filters,
      init: {
        BeforeUpload: (_, file) => {
          return false
        },
        FilesAdded: async (_, files) => {
          const file = files[0]
          if (this.BeforeUpload) {
            const result = this.BeforeUpload(file)
            if (!result) {
              if (this.processEl) {
                this.processEl.remove()
                this.processEl = null
              }
              return false
            }
          }
          if (file.name.includes('_')) {
            Message.error('文件命名不能以包含下划线')
            if (this.processEl) {
              this.processEl.remove()
              this.processEl = null
            }
            return false
          }
          await this.getAwsToken(file)
          this.initProcessEl()
          this.uploader.start()
        }
      }
    })
    this.uploader.init()
  }

  async getAwsToken(file) {
    const res = await serve.getAwsStsToken()
    if (res === serve.FAIL) {
      return serve.FAIL
    }
    this.awsTokens = res
    this.awsUpload(file)
  }

  async awsUpload(puploadFile) {
    const { bucketName, accessKeyId, secretAccessKey, sessionToken, region } =
      this.awsTokens
    const file = puploadFile.getNative()
    const fileKey = `${this.suffix}/${moment(new Date()).format(
      'YYYY-MM-DD'
    )}/${this.random ? `${randomString(32)}_` : ''}${file.name}`
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region,
      'Access-Control-Allow-Credentials': '*',
      ACL: 'public-read'
    })
    const params = {
      Bucket: bucketName,
      Key: fileKey,
      ContentType: file.type,
      Body: file
    }
    if (this.s3upload) {
      this.s3upload.removeAllListeners('httpUploadProgress')
      this.s3upload.abort()
    }
    this.s3upload = s3.upload(params, {
      partSize: 10 * 1024 * 1024,
      queueSize: 1
    })

    this.s3upload
      .on('httpUploadProgress', (progress) => {
        const percent =
          ((progress.loaded / progress.total) * 100).toFixed(0) + '%'
        this.processTxtEl.innerText = percent
      })
      .send((err, data) => {
        if (err) {
          if (err.code !== 'RequestAbortedError') {
            Message.error(err.message || '上传发生了错误')
          }

          this.uploader.state = plupload.FAILED
          puploadFile.status = plupload.FAILED
          this.processTxtEl.innerText = '重新上传'
          return
        }
        this.processEl.remove()
        this.processEl = null
        puploadFile.url = data.Location
        this.uploader.state = plupload.DONE
        puploadFile.status = plupload.DONE
        if (this.UploadComplete) {
          this.UploadComplete(this.uploader, puploadFile)
        }
      })
  }

  initProcessEl() {
    if (this.processEl) {
      this.processEl.remove()
      this.processEl = null
    }
    const parent = this.browse_button
    const processEl = document.createElement('span')
    processEl.style.cssText =
      'position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);'
    const processTxtEl = document.createElement('span')
    processTxtEl.style.cssText =
      'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff;font-size: 12px;'
    processTxtEl.innerHTML = '上传中...'
    processEl.appendChild(processTxtEl)
    this.processEl = processEl
    this.processTxtEl = processTxtEl
    parent.style.overflow = 'hidden'
    parent.appendChild(processEl)
  }
}

export default AwsUploader
