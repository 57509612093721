<template>
  <div class="page__device-manage">
    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="设备列表" name="/device-manage/list"></el-tab-pane>
      <el-tab-pane label="测试账号" name="/device-manage/account"></el-tab-pane>
    </el-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'DeviceManage',
  data() {
    return {
      activeName: this.$route.path
    }
  },
  methods: {
    handleClick(tab) {
      if (this.$route.path !== tab.name) this.$router.push(tab.name)
    }
  }
}
</script>
