import RouterHolder from '@/components/router-holder'
import DeviceManage from '@/views/device-manage'

import formatRoutesByEnhanceMeta from './helpers/formatRoutesByEnhanceMeta'
/**
 * @meta
 * title: 菜单名称
 * icon: 菜单显示图标
 * hideSubmenu: children 配置不显示在子菜单中
 * hideMenu: 当前配置不显示在菜单中
 */
const main = [
  {
    path: 'version-records',
    name: 'version-records',
    meta: { title: '发版记录', icon: '&#xe60b;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/version-records/list',
    children: [
      {
        path: 'list',
        meta: { title: '列表' },
        component: () =>
          import(
            /* webpackChunkName: "version-records" */ '@/views/version-records'
          )
      },
      {
        path: 'add',
        meta: { title: '发布新版本' },
        component: () =>
          import(
            /* webpackChunkName: "version-records" */ '@/views/version-records/add'
          )
      },
      {
        path: 'add/:id',
        meta: { title: '修改发布' },
        component: () =>
          import(
            /* webpackChunkName: "version-records" */ '@/views/version-records/add'
          )
      }
    ]
  },
  {
    path: 'app-configuration',
    name: 'app-configuration',
    meta: { title: 'App配置', eleIcon: 'el-icon-setting', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/app-configuration/list',
    children: [
      {
        path: 'list',
        meta: { title: '列表' },
        component: () =>
          import(
            /* webpackChunkName: "version-records" */ '@/views/app-configuration'
          )
      },
      {
        path: 'add',
        meta: { title: '新增配置' },
        component: () =>
          import(
            /* webpackChunkName: "version-records" */ '@/views/app-configuration/add'
          )
      },
      {
        path: 'add/:id',
        meta: { title: '修改配置' },
        component: () =>
          import(
            /* webpackChunkName: "version-records" */ '@/views/app-configuration/add'
          )
      }
    ]
  },
  {
    path: 'device-manage',
    name: 'device-manage',
    meta: { title: '设备管理', icon: '&#xe62a;', hideSubmenu: true },
    component: DeviceManage,
    redirect: '/device-manage/list',
    children: [
      {
        path: 'list',
        meta: { title: '设备列表' },
        component: () =>
          import(
            /* webpackChunkName: "version-manage" */ '@/views/device-manage/list'
          )
      },
      {
        path: 'account',
        meta: { title: '测试账号' },
        component: () =>
          import(
            /* webpackChunkName: "version-manage" */ '@/views/device-manage/account'
          )
      }
    ]
  },
  {
    path: 'upgrade-records',
    name: 'upgrade-records',
    meta: { title: '升级记录', icon: '&#xe620;' },
    component: () =>
      import(
        /* webpackChunkName: "upgrade-records" */ '@/views/upgrade-records'
      )
  },
  {
    path: 'password',
    name: 'password',
    meta: { title: '修改密码', icon: '&#xe620;', hideMenu: true },
    component: () =>
      import(
        /* webpackChunkName: "upgrade-records" */ '@/views/setting/password'
      )
  }
]

export default formatRoutesByEnhanceMeta(main)
