const commonApis = [
  {
    fn: 'getAliyunToken',
    method: 'get',
    url: '/dashboard/security-token-service/getToken'
  },
  {
    fn: 'getAwsStsToken',
    method: 'get',
    url: '/dashboard/security-token-service/getAwsStsToken'
  }
]

export default commonApis
