const operatorApis = [
  {
    fn: 'operatorLogin',
    method: 'post',
    url: '/dashboard/operator/login'
  },
  {
    fn: 'operatorLogout',
    method: 'post',
    url: '/dashboard/operator/logout'
  },
  {
    fn: 'operatorModifyPassword',
    method: 'post',
    url: '/dashboard/operator/modify-password'
  }
]

export default operatorApis
