const wareTypeList = [
  {
    label: 'APK',
    value: '1'
  },
  {
    label: '固件',
    value: '2'
  }
]

export default wareTypeList
