const userApis = [
  {
    fn: 'userList',
    method: 'get',
    url: '/dashboard/user/list',
    hideLoading: true
  },
  {
    fn: 'userAdd',
    method: 'post',
    url: '/dashboard/user/add'
  },
  {
    fn: 'userDelete',
    method: 'get',
    url: '/dashboard/user/delete',
    direct: true
  }
]

export default userApis
