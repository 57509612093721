<template>
  <div
    v-loading="tableLoading"
    element-loading-text="加载中"
    class="y-pro-table"
  >
    <el-table
      v-bind="formatTableProps"
      :header-cell-style="formatHeaderCellStyle"
    >
      <template
        v-for="(
          { render, slotname, headerSlotname, label, ...column }, i
        ) in columns"
      >
        <el-table-column v-bind="column" :key="i">
          <!-- 重置默认插槽 s -->
          <template slot-scope="scope">
            <!-- 自定义默认插槽  -->
            <template v-if="!!(slotname && $slots[slotname])">
              <slot :name="slotname" v-bind="scope"></slot>
            </template>
            <!-- 自定义渲染render方法  -->
            <template v-else-if="!!render">
              <custom
                :render="render"
                :column="column"
                :columnIndex="i"
                :scope="scope"
              ></custom>
            </template>
            <template v-else>
              <!-- 数据中也可单独返回一个函数 -->
              <template v-if="typeof scope.row[column.prop] === 'function'">
                <custom
                  :render="scope.row[column.prop]"
                  :column="column"
                  :columnIndex="i"
                  :scope="scope"
                ></custom>
              </template>
              <template v-else>
                {{ scope.row[column.prop] }}
              </template>
            </template>
          </template>
          <!-- 重置默认插槽 e -->

          <!-- 重置表头插槽 s -->
          <!-- 自定义表头插槽  -->
          <template slot="header" slot-scope="scope">
            <template v-if="!!(headerSlotname && $slots[headerSlotname])">
              <slot :name="headerSlotname" v-bind="scope"></slot>
            </template>
            <template v-else>
              {{ label }}
            </template>
          </template>
          <!-- 重置表头插槽 e -->
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      v-if="formatPagination"
      @size-change="sizeChange"
      @prev-click="prevChange"
      @next-click="nextChange"
      @current-change="currentChange"
      v-bind="formatPagination"
    ></el-pagination>
  </div>
</template>

<script>
import { Table, TableColumn, Pagination } from 'element-ui'
import custom from './coms/custom.vue'

import props from './config/props'
import tableProps from './config/table-props'
import pagination from './config/pagination'
import {
  paginationAdapter,
  tableHeaderCellStyleAdapter
} from './config/adapter'

import formatTableProps from './utils/formatTableProps'
import formatPagination from './utils/formatPagination'

export default {
  name: 'y-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    custom
  },
  props: {
    ...pagination,
    ...tableProps,
    ...props
  },
  data() {
    return {
      tableData: [],
      paginationData: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      loadingData: false
    }
  },
  computed: {
    /**
     * 是否组件自己负责数据逻辑
     */
    selfLogic() {
      return typeof this.fetch === 'function'
    },
    /**
     * 是否加载动画
     */
    tableLoading() {
      if (this.selfLogic) {
        return this.loadingData
      }
      // 表格数据是外部传入的时候, 使用外部的loading
      return this.loading
    },
    /**
     * 表格属性
     */
    formatTableProps() {
      const tableProps = formatTableProps(this.$props)
      if (this.selfLogic) {
        // 组件内部管理数据来源, 那么表格数据来源替换成this.tableData
        tableProps.data = this.tableData
      }
      return tableProps
    },
    /**
     * 分页属性
     */
    formatPagination() {
      // 分页控件是否展示
      if (!this.datapager) {
        return false
      }
      const pagination = formatPagination(this.$props)
      if (this.selfLogic) {
        if (!this.formatTableProps.data?.length) {
          return false
        }
        // 组件内部管理数据来源, 那么分页数据属性替换成this.paginationData
        return {
          ...pagination,
          ...this.paginationData
        }
      }
      return pagination
    },
    formatHeaderCellStyle() {
      return {
        ...tableHeaderCellStyleAdapter,
        ...formatTableProps.headerCellStyle
      }
    }
  },
  mounted() {
    if (this.initLoad) {
      this.fetchData()
    }
  },
  methods: {
    /**
     * 获取数据
     */
    fetchData(req) {
      if (!this.selfLogic) {
        return false
      }
      const { currentPage, pageSize } = this.paginationData
      // 请求参数
      let reqData = {
        currentPage,
        pageSize,
        ...this.query,
        ...req
      }

      if (typeof this.processRequest === 'function') {
        reqData = this.processRequest(reqData)
      }

      let apiConfig = {}
      if (this.fetch._method === 'get') {
        apiConfig.params = reqData
      } else if (this.fetch._method === 'post') {
        apiConfig.data = reqData
      } else {
        apiConfig = reqData
      }
      this.loadingData = true
      this.fetch(apiConfig)
        .then((res) => {
          this.loadingData = false
          if (this.processResponse) {
            res = this.processResponse(res)
          }

          if (!res) {
            return
          }

          this.tableData = res.records
          this.paginationData.total = +res[paginationAdapter.total]
          this.paginationData.currentPage = apiConfig.currentPage
          this.paginationData.pageSize = apiConfig.pageSize
          this.query = {
            ...this.query,
            ...req
          }

          // 当请求页面不是第一页的时候, 且返回的数据为空的话, 那么自动请求上一页数据
          if (apiConfig.currentPage > 1 && this.tableData.length === 0) {
            this.fetchData({
              currentPage: apiConfig.currentPage - 1
            })
          }
        })
        .catch(() => {
          this.loadingData = false
        })
    },
    /**
     * 每页条数变化
     */
    sizeChange(pageSize) {
      this.paginationData.pageSize = pageSize
      this.$emit('sizeChange', pageSize)
      this.fetchData({
        pageSize
      })
    },
    /**
     * 上一页
     */
    prevChange(page) {
      // this.paginationData.currentPage = page
      this.$emit('prevClick', page)
      // this.fetchData({ page })
    },
    /**
     * 下一页
     */
    nextChange(page) {
      // this.paginationData.currentPage = page
      this.$emit('nextClick', page)
      // this.fetchData({ page })
    },
    /**
     * 翻页
     */
    currentChange(currentPage) {
      this.paginationData.currentPage = currentPage
      this.$emit('currentChange', currentPage)
      this.fetchData({
        currentPage
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-pagination {
  margin-top: 20px;
  text-align: center;
}
</style>
