import Uploader from './uploader'
import serve from '@/serve'
import { randomString } from './tools'

class OssUploader extends Uploader {
  constructor(option) {
    super(option)
    this.tokenOption = null
    this.init()
  }

  // 缓存token, 不需要每次实例化的时候都去重新获取token
  static tokenOption = null

  /* 获取上传的token */
  async init() {
    if (!this.every) {
      if (OssUploader.tokenOption) {
        this.initUploader()
        return
      }
    }
    const res = await serve.getAliyunToken({
      params: { type: this.suffix }
    })
    if (res === serve.FAIL) {
      return
    }
    if (!this.every) {
      OssUploader.tokenOption = res
    } else {
      this.tokenOption = res
    }
    this.initUploader()
  }

  /* 设置uploader选项 */
  setUploaderOption(file) {
    if (!OssUploader.tokenOption && !this.tokenOption) {
      return
    }
    const {
      dir,
      policy,
      accessKeyId: OSSAccessKeyId,
      signature,
      host
    } = this.tokenOption || OssUploader.tokenOption
    const fileKey = this.random
      ? dir + randomString(32) + '_' + file.name
      : dir + file.name
    const multipart_params = {
      key: fileKey,
      policy,
      OSSAccessKeyId,
      signature,
      success_action_status: 200
    }
    file.url = host + '/' + fileKey
    this.uploader.setOption({
      url: host,
      multipart_params
    })
  }

  // setUploaderOption(file) {
  //   if (!OssUploader.tokenOption && !this.tokenOption) {
  //     return
  //   }
  //   const { bucketName, accessKeyId, region } =
  //     this.tokenOption || OssUploader.tokenOption
  //   const fileKey = this.random ? randomString(32) + '_' + file.name : file.name
  //   const host = `http://${bucketName}.s3.${region}.amazonaws.com`
  //   const multipart_params = {
  //     key: fileKey,
  //     Filename: fileKey,
  //     AWSAccessKeyId: accessKeyId,
  //     success_action_status: 200
  //   }
  //   file.url = host + '/' + fileKey
  //   this.uploader.setOption({
  //     url: host,
  //     multipart_params
  //   })
  // }
}

export default OssUploader
