const deviceApis = [
  {
    fn: 'deviceList',
    method: 'get',
    url: '/dashboard/device/list',
    hideLoading: true
  },
  {
    fn: 'deviceExport',
    method: 'get',
    url: '/dashboard/device/export',
    blob: true,
    restConfig: {
      responseType: 'blob'
    }
  }
]

export default deviceApis
