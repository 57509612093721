const deviceUpgradeRecordApis = [
  {
    fn: 'deviceUpgradeRecordList',
    method: 'get',
    url: '/dashboard/device-upgrade-record/list',
    hideLoading: true
  },
  {
    fn: 'deviceUpgradeRecordExport',
    method: 'get',
    url: '/dashboard/device-upgrade-record/export',
    blob: true,
    restConfig: {
      responseType: 'blob'
    }
  }
]

export default deviceUpgradeRecordApis
