<template>
  <div class="components__right-content">
    <el-dropdown>
      <span>{{ nickname }}</span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div @click="modify">修改密码</div>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <el-button
            style="text-align: center; padding: 0"
            type="text"
            @click="logout"
          >
            退出登录
          </el-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const defaultAvatar =
  'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'

export default {
  name: 'RightContent',
  components: {},
  computed: {
    ...mapState(['user']),
    nickname() {
      if (this.user) {
        return this.user.email
      }
      return '加载中'
    }
  },
  methods: {
    modify() {
      this.$router.push('/password').catch(() => {})
    },
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
.components__right-content {
  .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .nickname {
    margin-right: 8px;
    color: #606266;
  }
}
</style>
