const appApis = [
  {
    fn: 'fetchAppList',
    method: 'get',
    url: '/dashboard/publish-app/list',
    hideLoading: true
  },
  {
    fn: 'addApp',
    method: 'post',
    url: '/dashboard/publish-app',
    hideLoading: true
  },
  {
    fn: 'updateApp',
    method: 'put',
    url: '/dashboard/publish-app',
    hideLoading: true
  },
  {
    fn: 'updatePublishStatus',
    method: 'put',
    url: '/dashboard/publish-app/publish-status',
    hideLoading: true
  }
]

export default appApis
